import Head from "next/head";
import Footer from "../components/footer";

export default function Page404() {
  return (
    <>
      <Head>
        <title>404</title>
      </Head>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          height: "calc(100vh - var(--nextui--navbarHeight))",
        }}
      >
        <video
          loop={true}
          autoPlay={true}
          muted={true}
          style={{ width: "360px", maxWidth: "90vw", aspectRatio: "16 / 9" }}
        >
          <source src="/404_hevc.mp4" type="video/mp4; codecs=hvc1" />
          <source src="/404_av1.mp4" type="video/mp4; codecs=av01.0.05M.08" />
          <source src="/404.webm" type="video/webm; codecs=vp9" />
          <source src="/404.mp4" type="video/mp4" />
        </video>
      </div>
      <Footer />
    </>
  );
}
